<template>
    <section class="mt-3">
        <div class="row mx-0">
            <div class="col-8 br-8 px-3 py-3 bg-white">
                <p class="text-general br-5 mb-3 f-600">
                    Datos Generales
                </p>
                <div class="row mx-0">
                    <div class="col-5">
                        <p class="text-general f-15 f-500 pl-3">Nombre del cupón</p>
                        <el-input v-model="form.nombre" size="small" class="w-100" show-word-limit maxlength="40" @change="updateCampos" />
                    </div>
                    <div class="col-4">
                        <p class="text-general f-15 f-500 pl-3">Código</p>
                        <el-input v-model="form.codigo" size="small" class="w-100" show-word-limit maxlength="25" @change="updateCampos" />
                    </div>
                    <div class="col-9 my-3">
                        <p class="pl-3 text-general f-15 f-500">
                            Mensaje del cupón
                        </p>
                        <el-input v-model="form.descripcion" type="textarea" :rows="4" class="w-100" show-word-limit maxlength="500" @change="updateCampos" />
                    </div>
                </div>
            </div>
        </div>
        <div v-show="crear" class="row mx-0 px-5">
            <div class="col-7 d-flex justify-content-end">
                <div class="bg-general text-white f-17 br-12 py-1 px-3 cr-pointer" @click="accionContinuar">
                    Continuar
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Cupones from '~/services/cupones/cupones'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            form:{
                nombre:'',
                codigo:'',
                descripcion:''
            },
            crear:true
        }
    },
    computed:{
        ...mapGetters({
            datosBasicos: 'cupones/cupones/datosBasicosFuturo', //Datos del cupon hijo
        }),
        id_cupon_padre(){
            return Number(this.$route.params.id_cupon);
        },
        id_cupon(){
            return this.datosBasicos.id
        }
    },
    mounted(){
        console.log('monatr');
        if(this.id_cupon){
            this.crear = false
            this.initDatosBasicos()
        }else{
            this.getDatosBasicos()
        }
    },
    methods:{
        accionContinuar(){
            if(this.crear){
                this.crearCupon()
            }
        },
        async getDatosBasicos(){
            try {
                await this.$store.dispatch('cupones/cupones/getDatosBasicosFuturo',this.id_cupon_padre)
                if(this.id_cupon){
                    this.crear = false
                    this.initDatosBasicos()
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        async crearCupon(){
            try {

                this.form.tipo = 3
                this.form.id_padre = this.id_cupon_padre
                const {data} = await Cupones.crearCuponFuturo(this.form)
                this.$store.commit('cupones/cupones/set_datos_basicos_futuro',data.cupon)
                this.crear = false

            } catch (e){
                this.error_catch(e)
            }
        },
        initDatosBasicos(){
            let that = this
            if(_.isEmpty(this.datosBasicos)){
                setTimeout(function(){
                    that.initDatosBasicos()
                }, 800);
            }
            this.form.nombre = this.datosBasicos.nombre
            this.form.codigo = this.datosBasicos.codigo
            this.form.descripcion = this.datosBasicos.descripcion
        },
        async updateCampos(){ // esta accion es solo para editar
            try {
                if(this.crear) return
                const {data} = await Cupones.editarCupon(this.form,this.id_cupon)

            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.active-button{
    background-color: var(--color-general);
    color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
}
.inactive-button{
    background-color: #F5F5F5;
    color: #707070;
    box-shadow: 0px 3px 6px #00000029;
}
</style>
